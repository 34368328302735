import React, {useState, useEffect} from "react";
import Nav from "../shared/nav";
import Footer from "../shared/footer";
import axios from "axios";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Apropos() {
    const [partners, setPartners] = useState([]);
    const [testimonies, setTestimonies] = useState([]);

    useEffect(() => {
        AOS.init();
        getPartners()
        getTestimonies()

    }, [])

    const getTestimonies = () => {
        axios.get(`https://www.mvo.somee.com/api/Testimonies?page=1&count=50`)
        .then(data => {
            if(data.status == 200) {
                data.data[0].isActiv = true;
                
                setTestimonies(data.data)
            }
            else {
                setTestimonies([]);
            }
        })
        .catch(err => {
            setTestimonies([]);
            console.log(err)
        })
    }

    const getPartners = () => {
        axios.get(`https://www.mvo.somee.com/api/Partners`)
            .then(data => {
                if(data.status == 200) {
                    data.data[0].isActiv = true;
                    let donnee = [];

                    for (let i = 0; i < data.data.length; i += 3) {
                        donnee.push(data.data.slice(i, i + 3));
                    }
                    
                    setPartners(donnee);
                }
                else {
                    setPartners([]);
                }
            })
            .catch(err => {
                setPartners([]);
                console.log(err)
            })
    }
    return(
        <div>
            <Nav />
            <main className="main-content site-wrapper-reveal">
                    
                <section className="about-area">
                    <div className="container position-relative">
                        <div className="row">
                        <div className="col-lg-7" data-aos="fade-left" data-aos-duration="1200">
                            <div className="thumb">
                                <img src="assets/img/about/img6-removebg-preview.png" alt="Roomedia-HasTech" />
                            </div>
                        </div>
                        <div className="col-lg-5" data-aos="fade-right" data-aos-duration="1200">
                            <div className="content mb-md-60">
                                <div className="section-title xs-pr-15 pr-70">
                                    <h2 className="title mb-sm-10 mb-15 mt-30 text-center" style={{fontSize:'48px'}}>Fournir des services et des solutions de<span style={{color:'#003366'}}> la plus haute qualité </span> à nos clients</h2>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="shape-layer">
                        <div className="shape shape-one"></div>
                        <div className="shape shape-two"></div>
                        <div className="shape shape-three"></div>
                        <div className="shape shape-four"></div>
                        <div className="shape shape-five"></div>
                        <div className="shape shape-six"></div>
                        <div className="shape shape-eight"></div>
                        </div>
                    </div>
                </section>

                <section className="why-choose-area bgcolor-f4">
                    <div className="container">
                        <div className="row" data-aos="fade-up" data-aos-duration="1200">
                            <div className="col-lg-6 col-xl-6 col-xxl-6">
                                <div className="content mb-md-60">
                                    <div className="section-title xs-pr-15 pr-70">
                                        <h2 className="title mb-sm-30 mb-55">Connaître notre entreprise <span style={{color:'#003366'}}> LogiqSoft</span></h2>
                                        <p>LogiqSoft SARL, vous nous trouvez à Lubumbashi, à Kolwezi et en ligne, nous sommes une entreprise constituée d’une équipe forte et efficace d’experts numériques très engagée à contribuer de manière significative et positive à la conception, l’amélioration et la maintenance de vos solutions numériques de gestion, de communication, production et de sécurité de votre business Établie depuis 2019, notre expertise et nos méthodes de conception et de développement constituent le socle de notre stratégie numérique, sur ce vous êtes toujours sûr d’obtenir les solutions numériques à jour à la hauteur de vos attentes remplissant vos exigences d’innovation afin de faire la différence face à la concurrence. Nous sommes motivés et passionnés par les nouvelles technologies et la manière dont elles vont résoudre vos difficultés business.</p>
                                    </div>
                                    <div className="feature-item">
                                        <h5>Projet Réussi</h5>
                                        <p>LogiqSoft SARL, votre partenaire de confiance pour les solutions numériques, a aidé ses clients à atteindre un taux de réussite dans la conception, l'amélioration et la maintenance de leurs solutions de gestion, de communication, de production et de sécurité des affaires.</p>
                                    </div>
                                    <div className="feature-item">
                                        <h5>Avis des Clients</h5>
                                        <p>LogiqSoft SARL est plébiscité par ses clients pour son engagement exceptionnel dans la création de solutions numériques innovantes. Nous sommes fiers de notre capacité à répondre aux attentes de nos clients en leur fournissant des solutions à la pointe de la technologie.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="thumb">
                                    <img src="assets/img/about/img4.jpg" alt="Roomedia-HasTech" />
                                </div>
                        </div>
                        </div>
                    </div>
                </section>

                <section className="testimonial-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="testimonial-content-area">
                                <div id="carouselExampleControlsNoTouching" className="carousel slide" data-bs-touch="false">
                                        <div className="carousel-inner">
                                            
                                            {partners.map(data => 
                                                <div key={data[0].id} className={data[0].isActiv?"carousel-item active":"carousel-item"} style={{marginLeft:'10%'}}>
                                                    {data.map(partner =>
                                                        <div key={partner.id+'x'} className="col-md-4 p-1" style={{display:'inline-block'}}>
                                                            <img src={partner.imageLink} alt={partner.company} />
                                                        </div>
                                                    )}
                                                </div>
                                                            )}
                                        </div>
                                        <button style={{border:'none', backgroundColor:'transparent', color:'black'}} className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev">
                                            <span style={{fontSize:'30px'}}><i className="lnr lnr-chevron-left"></i></span>
                                        </button>
                                        <button style={{border:'none', backgroundColor:'transparent', color:'black'}} className="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next">
                                            <span style={{fontSize:'30px'}}><i className="lnr lnr-chevron-right"></i></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                    
                <section className="team-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h2 className="title">Notre <span style={{color:'#003366'}}>Equipe</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className="row row-gutter-70">
                            <div className="col-md-3" data-aos="fade-left" data-aos-duration="1000">
                                <div className="team-member mb-md-60">
                                    <div className="thumb">
                                        <img src="assets/img/team/tm1.jpg" alt="Image" />
                                        <div className="member-icons">
                                        <a href="https://www.linkedin.com/in/nathan-kadiambila/" target="_blank"><i className="social_linkedin_square"></i></a>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="member-info">
                                            <h4 className="name">
                                                <a>Nathan kadiambila</a>
                                            </h4>
                                            <h6 className="designation">General manager</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3" data-aos="fade-left" data-aos-duration="1000">
                                <div className="team-member mb-md-60">
                                    <div className="thumb">
                                        <img src="assets/img/team/t1.jpg" alt="Image" />
                                        <div className="member-icons">
                                        <a href="https://x.com/Blaise00020002?t=kDB4WbuWC-01MYOv5fTAeQ&s=09" target="_blank"><i className="social_twitter"></i></a>
                                        <a href="https://www.linkedin.com/in/blaise-kamwadi-dylan-563701199" target="_blank"><i className="social_linkedin_square"></i></a>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="member-info">
                                            <h4 className="name">
                                                <a>Dylan Kamwadi</a>
                                            </h4>
                                            <h6 className="designation">Executif Manager</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3" data-aos="fade-left" data-aos-duration="1000">
                                <div className="team-member">
                                    <div className="thumb">
                                        <img src="assets/img/team/tm2.jpg" alt="Image" />
                                        <div className="member-icons">
                                        <a href="https://x.com/KabongoJon33324?t=_J36N-j6Sv-V9mMZFEWRjA&s=09" target="_blank"><i className="social_twitter"></i></a>
                                        <a href="https://www.linkedin.com/in/jonathan-kabongo-a000042a7/" target="_blank"><i className="social_linkedin_square"></i></a>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="member-info">
                                            <h4 className="name">
                                                <a>Jonathan Kabongo</a>
                                            </h4>
                                            <h6 className="designation">Humain Ressource</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3" data-aos="fade-left" data-aos-duration="1000">
                                <div className="team-member mb-md-60">
                                    <div className="thumb">
                                        <img src="assets/img/team/tm3.jpg" alt="Image" />
                                        <div className="member-icons">
                                        <a href="https://x.com/ken_mwepu?t=9Fg6jEfWQGpKLGvhGD1lcQ&s=09" target="_blank"><i className="social_twitter"></i></a>
                                        <a href="https://www.linkedin.com/in/ken-mwepu-66734a1ba/" target="_blank"><i className="social_linkedin_square"></i></a>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="member-info">
                                            <h4 className="name">
                                                <a>Ken Mwepu</a>
                                            </h4>
                                            <h6 className="designation">Lead Developer</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3" data-aos="fade-right" data-aos-duration="1000">
                                <div className="team-member mb-md-60">
                                    <div className="thumb">
                                        <img src="assets/img/team/tm7.jpg" alt="Image" />
                                        <div className="member-icons">
                                        <a href="https://www.linkedin.com/in/annette-mbwese-ab21aa290/" target="_blank"><i className="social_linkedin_square"></i></a>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="member-info">
                                            <h4 className="name">
                                                <a>Annette Mbwese</a>
                                            </h4>
                                            <h6 className="designation">Sécretariat Générel</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3" data-aos="fade-right" data-aos-duration="1000">
                                <div className="team-member">
                                    <div className="thumb">
                                        <img src="assets/img/team/tm6.jpg" alt="Image" />
                                        <div className="member-icons">
                                            <a href="https://x.com/AlcyaKalaba?t=ugU1O3zkI1EKir0ugSFJSg&s=09" target="_blank"><i className="social_twitter"></i></a>
                                            <a href="https://www.linkedin.com/in/alcya-kalaba-klb19/" target="_blank"><i className="social_linkedin_square"></i></a>
                                        </div>
                                    </div>
                                <div className="content">
                                    <div className="member-info">
                                        <h4 className="name">
                                            <a>Alcya Kalaba</a>
                                        </h4>
                                        <h6 className="designation">Community Manager</h6>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-3" data-aos="fade-right" data-aos-duration="1000">
                                <div className="team-member mb-md-60">
                                    <div className="thumb">
                                        <img src="assets/img/team/tm5.jpg" alt="Image" />
                                        <div className="member-icons">
                                        <a href="https://www.linkedin.com/in/gu%C3%A9daliah-lwimbi-b387522b7" target="_blank"><i className="social_linkedin_square"></i></a>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="member-info">
                                        <h4 className="name">
                                            <a>Guedaliah Lwimbi</a>
                                        </h4>
                                        <h6 className="designation">Technical Manager</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3" data-aos="fade-right" data-aos-duration="1000">
                                <div className="team-member">
                                    <div className="thumb">
                                        <img src="assets/img/team/tm4.jpg" alt="Image" />
                                        <div className="member-icons">
                                            <a href="https://logiqsoft.io/#" target="_blank"><i className="social_linkedin_square"></i></a>
                                        </div>
                                    </div>
                                <div className="content">
                                    <div className="member-info">
                                    <h4 className="name">
                                        <a>Berto Lumuna</a>
                                    </h4>
                                    <h6 className="designation">Designer</h6>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="testimonial-area" data-aos="fade-up" data-aos-duration="1000" style={{marginTop: "-10%"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5">
                                <div className="section-title">
                                    <h2 className="title">Quelques temoignages de <span style={{color:'#003366'}}>nos clients</span></h2>
                                
                            </div>
                        </div>
                        <div className="col-xl-7">
                                <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-inner p-4">
                                        {testimonies.map(testimonie =>
                                        <div className={testimonie.isActiv?"carousel-item active":"carousel-item"}>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <img src="assets/img/quote-open.png" alt="" width={50} height={50} />
                                                </div>
                                                <div className="col-md-8 p-1">
                                                    <p style={{textAlign:'justify'}}>{testimonie.content}</p>
                                                    <h6 style={{textAlign:'end'}}>{testimonie.author}</h6>
                                                </div>
                                                <div className="col-md-2">
                                                <img src="assets/img/quote-close.png" alt="" width={50} height={50} />
                                                </div>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                </div>
                        </div>
                    </div>
                    </div>
                </section>
            </main>
            <Footer duration={1200} />
        </div>
    )
}

export default Apropos;